import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,
} from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
} from 'react-share';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    backgroundColor: '#fff'
  },
  margin: {
    margin: theme.spacing(1),
  },
  
}));

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const IntroPaper = withStyles({
  root: {
    backgroundImage: 'url(/tunnista_bg.png)',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    paddingBottom: '3%',
    marginTop: '5%',
    paddingTop: '110%',
    textAlign: 'center',
    overflow: 'hidden',
    color: '#ffffff',
    backgroundColor: '#000000',
    fontSize: '18px',
    lineHeight: '24px',
    //fontWeight: 'medium',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
})(Paper);

const SartButton = withStyles({
  root: {
    margin: '0',
    maxWidth: '60%',
    /*boxShadow: '2px 2px 8px 0px rgba(255,255,255,1);',*/
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '34px',
    color: '#4E4C4C',
    padding: '0',
    height: '50px',
    //border: '1px solid',
    backgroundColor: '#F3F1D7',
    fontWeight: 'bold',
    //borderColor: '#ededed',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:hover': {
      backgroundColor: '#F3F1D7',
      // borderColor: '#dedede',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F3F1D7',
      //borderColor: '#dadada',
    },
    '&:focus': {
      //boxShadow: '0 0 0 0.2rem rgba(230,230,230,.5)',
    },
  },
})(Button);

const CloseButton = withStyles({
  root: {
    margin: '0',
    maxWidth: '60%',
    /*boxShadow: '2px 2px 8px 0px rgba(255,255,255,1);',*/
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '34px',
    color: '#4E4C4C',
    padding: '0',
    height: '50px',
    //border: '1px solid',
    backgroundColor: '#F3F1D7',
    fontWeight: 'bold',
    //borderColor: '#ededed',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:hover': {
      backgroundColor: '#F3F1D7',
      // borderColor: '#dedede',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F3F1D7',
      //borderColor: '#dadada',
    },
    '&:focus': {
      //boxShadow: '0 0 0 0.2rem rgba(230,230,230,.5)',
    },
  },
})(Button);

const BuyButton = withStyles({
  root: {
    margin: '0',
    maxWidth: '60%',
    /*boxShadow: '2px 2px 8px 0px rgba(255,255,255,1);',*/
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '34px',
    color: '#4E4C4C',
    padding: '0',
    height: '50px',
    //border: '1px solid',
    backgroundColor: '#F3F1D7',
    fontWeight: 'bold',
    //borderColor: '#ededed',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:hover': {
      backgroundColor: '#F3F1D7',
      // borderColor: '#dedede',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F3F1D7',
      //borderColor: '#dadada',
    },
    '&:focus': {
      //boxShadow: '0 0 0 0.2rem rgba(230,230,230,.5)',
    },
  },
})(Button);

const AnswerButton = withStyles({
  root: {
    margin: '3% 0',
    //maxWidth: '100%',
    width: '90%',
    //heigth: '60px',
    boxShadow: 'none',
    //boxShadow: '2px 2px 8px 0px rgba(255,255,255,1);',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    height: '80px',
    color: '#4E4C4C',
    padding: '6px 12px',
    //border: '1px solid',
    borderRadius: '4px',
    backgroundColor: '#F3F1D7',
    //borderColor: '#ededed',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:hover': {
      backgroundColor: '#F3F1D7',
      //borderColor: '#dedede',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#F3F1D7',
      //borderColor: '#dadada',
    },
    '&:focus': {
      //boxShadow: '0 0 0 0.2rem rgba(230,230,230,.5)',
    },
  },
})(Button);

const QuestionPaper = withStyles({
  root: {
    maxWidth: '100%',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    //heigth: '200px',
    //border: '1px solid',
    textAlign: 'center',
    marginBottom: '0%',
    paddingBottom: '2%',
    paddingTop: '20%',
    backgroundColor: '#000',
    //borderColor: '#ededed',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
})(Paper);

const ResultPaperSummary = withStyles({
  root: {
    padding: '1%',
    marginBottom: '3%',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: '#000',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
})(Paper);

const ColorLink = withStyles({
  root: {
    fontSize: '20px',
    //lineHeight: '24px',
    color: '#707070',
    textDecoration:'underline',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:hover': {
      textDecoration:'none',
    },
    '&:active': {
      textDecoration:'none',
    },
    '&:focus': {
      textDecoration:'none',
    },
  },
})(Link);

const ResultPaper = withStyles({
  root: {
    padding: '1%',
    paddingRight: '3%',
    paddingLeft: '3%',
    marginBottom: '3%',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#707070',
    textAlign: 'center',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
})(Paper);

const SharePaper = withStyles({
  root: {
    padding: '1%',
    paddingRight: '3%',
    paddingLeft: '3%',
    marginBottom: '3%',
    fontSize: '20px',
    lineHeight: '24px',
    //color: '#707070',
    backgroundColor: '#000',
    display: 'inline-block',
    textAlign: 'center',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
})(Paper);

const TonyPaper = withStyles({
  root: {
    //padding: '1%',
    //paddingRight: '3%',
    //paddingLeft: '3%',
    //marginBottom: '3%',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#bebebe',
    backgroundColor: '#000',
    //display: 'inline-block',
    textAlign: 'center',
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
})(Paper);

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const quiz = {
  "questions":[
    {"id":1,"question":"Astuessasi hissiin","choices":[{"title":"painat oven sulkevaa nappia ","value":"punainen"},{"title":"hymyilet hississä oleville","value":"vihreä"},{"title":"sanot jotain kepeää hississä oleville","value":"keltainen"},{"title":"olet vaiti ajatuksiisi uppoutuneena","value":"sininen"}]},
    {"id":2,"question":"Asunnon ostossa tärkeintä on ","choices":[{"title":"viihtyisä asuinympäristö ja ystävälliset naapurit","value":"vihreä"},{"title":"hyvä tunne asunnosta","value":"keltainen"},{"title":"se, että olet selvittänyt käyttökulut ja korjaustarpeet","value":"sininen"},{"title":"se, että saat neuvoteltua hyvän hinnan","value":"punainen"}]},
    {"id":3,"question":"Esiintymistilanteessa ajattelet:","choices":[{"title":"mitähän katsojat minusta ajattelevat?","value":"vihreä"},{"title":"onkohan minulla riittävästi faktoja esityksessäni?","value":"sininen"},{"title":"onhan kaikilla varmasti hauskaa?","value":"keltainen"},{"title":"miettikööt mitä vain, kunhan viesti mene perille!","value":"punainen"}]},
    {"id":4,"question":"Esimiehenä olet/olisit","choices":[{"title":"innostava","value":"keltainen"},{"title":"hallitseva","value":"punainen"},{"title":"asiallinen ","value":"sininen"},{"title":"rauhallinen","value":"vihreä"}]},
    {"id":5,"question":"Ikävintä on ","choices":[{"title":"synkistely","value":"keltainen"},{"title":"päsmäröinti","value":"vihreä"},{"title":"välinpitämättömyys","value":"punainen"},{"title":"tosiasioiden väheksyminen","value":"sininen"}]},
    {"id":6,"question":"Jos joku loukkaa sinua tahallisesti,","choices":[{"title":"annat heti samalla mitalla takaisin","value":"punainen"},{"title":"kuittaat tilanteen huumorilla","value":"keltainen"},{"title":"mietit, miksi hän sanoi niin","value":"sininen"},{"title":"mietit, miksi hän ei pidä sinusta","value":"vihreä"}]},
    {"id":7,"question":"Joskus olet","choices":[{"title":"liian itsepäinen","value":"punainen"},{"title":"liian innostunut","value":"keltainen"},{"title":"liian huolehtivainen","value":"vihreä"},{"title":"liian vetäytyvä","value":"sininen"}]},
    {"id":8,"question":"Mitä ajattelet, kun myöhästyt?","choices":[{"title":"en myöhästy","value":"sininen"},{"title":"myöhästyn usein, mutta se ei haittaa","value":"keltainen"},{"title":"myöhästyminen vaivaa minua","value":"vihreä"},{"title":"myöhästyn vain todella hyvästä syystä","value":"punainen"}]},
    {"id":9,"question":"Jos olisit maa, olisit","choices":[{"title":"Islanti","value":"sininen"},{"title":"Japani","value":"vihreä"},{"title":"Italia","value":"punainen"},{"title":"Yhdysvallat","value":"keltainen"}]},
    {"id":10,"question":"Keskusteluissa","choices":[{"title":"tuot oman mielipiteesi voimakkaasti esiin","value":"punainen"},{"title":"pitäydyt tosiasioissa","value":"sininen"},{"title":"höystät keskustelua huumorilla ja tarinoilla","value":"keltainen"},{"title":"tarkkailet ensin muita ja puhut, jos asiasi on tärkeä","value":"vihreä"}]},
    {"id":11,"question":"Huono puolesi on, että","choices":[{"title":"tarkkailet, noudattavatko toiset sääntöjä","value":"sininen"},{"title":"kiivastut helposti","value":"punainen"},{"title":"innostut herkästi, mutta et pysty pitämään kaikkia lupauksiasi","value":"keltainen"},{"title":"saatat joskus uhriutua","value":"vihreä"}]},
    {"id":12,"question":"Tulevaisuudensuunnitelmasi ovat ","choices":[{"title":"tavoitteellisia ja haasteellisia","value":"punainen"},{"title":"innostavia ja luovia","value":"keltainen"},{"title":"analyyttisiä ja realistisia","value":"sininen"},{"title":"ihmisläheisiä ja konkreettisia","value":"vihreä"}]},
    {"id":13,"question":"Kun olet stressaantunut,","choices":[{"title":"haluat olla yksin","value":"sininen"},{"title":"kiukuttelet","value":"punainen"},{"title":"puhut toisille tilanteestasi","value":"vihreä"},{"title":"lähdet juhlimaan","value":"keltainen"}]},
    {"id":14,"question":"Kun ostat uuden laitteen,","choices":[{"title":"ostat samanlaisen kuin muilla","value":"vihreä"},{"title":"tutustut tarkasti vehkeen ominaisuuksiin","value":"sininen"},{"title":"vertailet tarkasti hintoja","value":"punainen"},{"title":"ostat uusimman mallin ja esittelet sitä muille","value":"keltainen"}]},
    {"id":15,"question":"Miten suutut?","choices":[{"title":"harvoin, mutta silloin se on rajua","value":"vihreä"},{"title":"todella harvoin, mutta saatan jurnuttaa usein","value":"sininen"},{"title":"nopeasti ja annan toisten kuulla kunniansa","value":"punainen"},{"title":"nopeasti, mutta pian olen taas iloinen","value":"keltainen"}]},
    {"id":16,"question":"Mikä sanonta sopii sinulle parhaiten?","choices":[{"title":"”Asialliset hommat hoidetaan ja muuten ollaan kuin Ellun kanat.”","value":"vihreä"},{"title":"”Järjestys olla pitää.”","value":"sininen"},{"title":"”Ei voitto, vaan murskavoitto.”","value":"punainen"},{"title":"”Iloa huuleen, vaikka pää märkänis.”","value":"keltainen"}]},
    {"id":17,"question":"Olet useimmiten","choices":[{"title":"aikaansaava","value":"punainen"},{"title":"ystävällinen","value":"vihreä"},{"title":"asiallinen","value":"sininen"},{"title":"iloinen","value":"keltainen"}]},
    {"id":18,"question":"Olet luonteeltasi","choices":[{"title":"kiihkeä","value":"punainen"},{"title":"pirskahteleva","value":"keltainen"},{"title":"lempeä","value":"vihreä"},{"title":"harkitseva","value":"sininen"}]},
    {"id":19,"question":"Parasta sinussa on","choices":[{"title":"säntillisyys","value":"sininen"},{"title":"itsevarmuus","value":"punainen"},{"title":"luovuus ","value":"keltainen"},{"title":"huomaavaisuus","value":"vihreä"}]},
    {"id":20,"question":"Ryhmätöissä","choices":[{"title":"otat aloitteen ja pidät huolta kepeästä tunnelmasta ","value":"keltainen"},{"title":"huolehdit, että asiat ovat oikein","value":"sininen"},{"title":"haluat, että kaikki tulevat kuulluksi","value":"vihreä"},{"title":"tärkeintä on olla ensimmäisenä valmiina","value":"punainen"}]},
    {"id":21,"question":"Seurapelejä pelatessa tärkeintä on ","choices":[{"title":"kiva tunnelma","value":"keltainen"},{"title":"voittaminen","value":"punainen"},{"title":"yhdessäolo","value":"vihreä"},{"title":"sääntöjen noudattaminen","value":"sininen"}]},
    {"id":22,"question":"Sukujuhlissa ajattelet:","choices":[{"title":"joko pääsee pois?","value":"sininen"},{"title":"mukava nähdä sukulaisia","value":"vihreä"},{"title":"kevyt keskustelu tuntemattomien kanssa avartaa","value":"keltainen"},{"title":"taas menee aikaa hukkaan","value":"punainen"}]},
    {"id":23,"question":"Miten suunnittelet vapaa-aikaasi?","choices":[{"title":"tarkasti ja kauan","value":"sininen"},{"title":"en suunnittele, ex tempore on ihaninta!","value":"keltainen"},{"title":"kysyn muilta, mitä he tahtovat","value":"vihreä"},{"title":"tehokkaasti","value":"punainen"}]},
    {"id":24,"question":"Teet työtäsi mieluiten","choices":[{"title":"avokonttorissa, jossa voit tarvittaessa vetäytyä omaan rauhaan","value":"vihreä"},{"title":"yksin, esimerkiksi etätöissä kotona","value":"sininen"},{"title":"paikalla ei ole merkitystä, kunhan pääset tavoitteisiisi","value":"punainen"},{"title":"ryhmätyötilassa muiden kanssa","value":"keltainen"}]},
    {"id":25,"question":"Toiset ajattelevat, että olet ","choices":[{"title":"kekseliäs","value":"keltainen"},{"title":"pitkäpinnainen","value":"vihreä"},{"title":"syventyvä","value":"sininen"},{"title":"tavoitteellinen","value":"punainen"}]},
    {"id":26,"question":"Toisten mielestä ärsyttävintä sinussa on ","choices":[{"title":"päälle puhuminen","value":"keltainen"},{"title":"kärsimättömyys","value":"punainen"},{"title":"tunnelman pilaaminen tosiasioilla","value":"sininen"},{"title":"päättämättömyys","value":"vihreä"}]},
    {"id":27,"question":"Työssäsi haluaisit olla  ","choices":[{"title":"virheetön","value":"sininen"},{"title":"hyvissä väleissä toisten kanssa","value":"vihreä"},{"title":"ilopilleri","value":"keltainen"},{"title":"tehokas","value":"punainen"}]},
    {"id":28,"question":"Tärkeintä elämässä on","choices":[{"title":"ilo","value":"keltainen"},{"title":"jämäkkyys","value":"punainen"},{"title":"turvallisuus","value":"vihreä"},{"title":"loogisuus","value":"sininen"}]},
    {"id":29,"question":"Vapaa-aikasi vietät mieluiten","choices":[{"title":"hauskassa ystäväporukassa","value":"keltainen"},{"title":"sukulaisten kanssa","value":"vihreä"},{"title":"yksin tai kaksin omassa rauhassa","value":"sininen"},{"title":"tekemällä sinulle tärkeitä asioita","value":"punainen"}]},
    {"id":30,"question":"Vastoinkäymiset elämässä","choices":[{"title":"stressaavat paljon","value":"vihreä"},{"title":"kohdataan mahdollisimman positiivisesti","value":"keltainen"},{"title":"vaativat kunnollisen suunnitelman","value":"punainen"},{"title":"kohdataan järkevästi","value":"sininen"}]},
    {"id":31,"question":"Voisit olla työksesi","choices":[{"title":"tutkijana tai tarkastajana","value":"sininen"},{"title":"matkaoppaana tai tosi-tv-juontajana","value":"keltainen"},{"title":"johtajana tai projektivastuussa","value":"punainen"},{"title":"hoitoalalla tai opettajana","value":"vihreä"}]},
    {"id":32,"question":"Miten suhtaudut, kun ovella on yllätysvieraita?","choices":[{"title":"noh, ehkä tämän jaksaa, koska ystäviä ovat","value":"vihreä"},{"title":"en avaa ovea","value":"sininen"},{"title":"tosi kiva!","value":"keltainen"},{"title":"avaan oven harmissani suunnitelmien muutoksesta","value":"punainen"}]}
  ]
};

class App extends Component {
    
    state = {
        currentPhase: 0,
        currentQuestion:null,
        //totalPhases: 0,
  //      quiz:null
        panelExpanded:'',
        answers:[],
        resultsOrder:[],
        finalResult:[],
        isMobile:window.innerWidth<1000,
    };
  
    componentWillMount() {
    }

    componentWillUnmount() {
    }
    
    componentDidMount() {
      this.setState({currentQuestion:quiz.questions.find(q => {return q.id == 1;})});
      //var quizJson = JSON.parse(quizJsonString);
      //this.setState({totalPhases:quizJson.questions});
      /*var quiz = {};
      quiz.questions = [];
      quizJson.questions.map(q => {
        let quest = {};
        quest.id = q.nro;
        quest.question = q.q;
        quest.choices = [];
        let choice = {};
        choice.title = q.a1;
        choice.value = q.r1;
        quest.choices.push(choice);
        choice = {};
        choice.title = q.a2;
        choice.value = q.r2;
        quest.choices.push(choice);
        choice = {};
        choice.title = q.a3;
        choice.value = q.r3;
        quest.choices.push(choice);
        choice = {};
        choice.title = q.a4;
        choice.value = q.r4;
        quest.choices.push(choice);
        quiz.questions.push(quest);
      });
      
      this.setState({quiz:quiz});*/
    }
    
    handleExpandClick = (e,p) => {
      this.setState({panelExpanded:p});
    }
    
    handleChoiceClick = (e,v,n) => {
      //console.log(e);
      
      let a = {};
      a.id = n;
      a.choice = v;
      let aa = this.state.answers;
      aa.push(a);
      this.setState({answers:aa});
      
      if (n < quiz.questions.length)
        this.setState({currentQuestion:quiz.questions.find(q => {return q.id == n+1;})});
      //else
      if (n == quiz.questions.length) {
      //if (n == 3) {
        this.countFinalResult();
        this.setState({currentPhase:3}); 
      }
    }
    
    countFinalResult = () => {
      let fR = this.state.finalResult;
      let res = {};
      res.cnt = this.state.answers.filter(a => {return a.choice == "keltainen";}).length;
      res.txt = 'keltaista';
      res.colorCode = '#FFEC50';
      fR.push(res);
      res = {};
      res.cnt = this.state.answers.filter(a => {return a.choice == "sininen";}).length;
      res.txt = 'sinistä';
      res.colorCode = '#2789CB';
      fR.push(res);
      res = {};
      res.cnt = this.state.answers.filter(a => {return a.choice == "punainen";}).length;
      res.txt = 'punaista';
      res.colorCode = '#FF4C30';
      fR.push(res);
      res = {};
      res.cnt = this.state.answers.filter(a => {return a.choice == "vihreä";}).length;
      res.txt = 'vihreää';
      res.colorCode = '#4BB556';
      fR.push(res);
      fR.sort((a,b) => {return b.cnt - a.cnt;})
      this.setState({finalResult:fR});
    }
    
    buyBook = () => {
      window.location.href='https://www.suomalainen.com/products/tunnista-tyyppisi';
    }
    
    getMainColor = () => {
      let mainColor = '';
      let cnt = 0;
      if (this.state.answers.filter(a => {return a.choice == "keltainen";}).length > cnt) {
        cnt = this.state.answers.filter(a => {return a.choice == "keltainen";}).length; 
        mainColor = "KELTAINEN";
      }
      if (this.state.answers.filter(a => {return a.choice == "vihreä";}).length > cnt) {
        cnt = this.state.answers.filter(a => {return a.choice == "vihreä";}).length; 
        mainColor = "VIHREÄ";
      }
      if (this.state.answers.filter(a => {return a.choice == "sininen";}).length > cnt) {
        cnt = this.state.answers.filter(a => {return a.choice == "sininen";}).length; 
        mainColor = "SININEN";
      }
      if (this.state.answers.filter(a => {return a.choice == "punainen";}).length > cnt) {
        cnt = this.state.answers.filter(a => {return a.choice == "punainen";}).length; 
        mainColor = "PUNAINEN";
      }
      return mainColor;
    }

    render() {
      
      return (
        <React.Fragment>
          <CssBaseline />
          <Container style={{flexGrow:1, maxWidth:'800px', paddingTop:'0%', paddingBottom:'2%', textAlign:'center'}}>
            {this.state.currentPhase == 0 && (<div style={{height:'100vh'}}><IntroPaper style={this.state.isMobile?{paddingTop:'60vh'}:{}}>
              <p style={{marginTop: '0%'}}>Mikä väri kuvastaa sinua parhaiten? Vastaa temperamenttitestin kysymyksiin valitsemalla sopivin neljästä vaihtoehdosta.</p>
            </IntroPaper>
            <SartButton key="start" variant="contained" color="primary" fullWidth={true} onClick={() => {this.setState({currentPhase:2})}}>Aloita testi</SartButton>
            </div>)}
            
            {this.state.currentPhase == 1 && <SartButton key="start" variant="contained" color="primary" fullWidth={true} onClick={() => {this.setState({currentPhase:2})}}>Aloita testi</SartButton>}
            
            {this.state.currentPhase == 2 && <QuestionPaper>
            {this.state.currentQuestion && <span style={{height: '80px', display:'block'}}>{this.state.currentQuestion.question}</span>}
            </QuestionPaper>}
            {this.state.currentPhase == 2 && this.state.currentQuestion ? this.state.currentQuestion.choices.map(c => 
              <AnswerButton key={`${this.state.currentQuestion.id}_${c.value}`} variant="contained" color="primary" fullWidth={true}  onClick={(e) => this.handleChoiceClick(e, c.value, this.state.currentQuestion.id)}>{c.title}</AnswerButton>
            ) : ''} 
            {this.state.currentPhase == 2 && <QuestionPaper style={{paddingTop:'10%'}}>
            {this.state.currentQuestion && <span>{this.state.currentQuestion ? this.state.currentQuestion.id : 0} / {quiz.questions.length}</span>}
            </QuestionPaper>}
            
            {this.state.currentPhase == 3 && <ResultPaperSummary>
              <p>Värityyppisi näyttää tällaiselta</p>
              {this.state.finalResult.map(res => 
              <span style={{display:'block'}}>{res.cnt} <span style={{color:res.colorCode}}>{res.txt}</span></span>
              )}
              <p>Ekstroverttivärit (punainen ja keltainen) yhteensä {this.state.answers.filter(a => {return a.choice == "punainen";}).length + this.state.answers.filter(a => {return a.choice == "keltainen";}).length}.</p>
              <p>Introverttivärit (sininen ja vihreä) yhteensä {this.state.answers.filter(a => {return a.choice == "sininen";}).length + this.state.answers.filter(a => {return a.choice == "vihreä";}).length}.</p>
            </ResultPaperSummary>}
            
            {this.state.currentPhase == 3 && <ResultPaper>
              <p>Oletko ulospäin suuntautunut ekstrovertti tai omaa rauhaa kaipaava introvertti? Jos vastaukset menivät jokseenkin tasan, olet luultavasti ambivertti. <ColorLink component="button" style={{color:'#FF4C30'}} onClick={() => {this.setState({currentPhase:5})}}>Punainen</ColorLink> ja <ColorLink component="button" style={{color:'#2789CB'}} onClick={() => {this.setState({currentPhase:5})}}>sininen</ColorLink> ovat asiakeskeisiä, <ColorLink component="button" style={{color:'#FFEC50'}} onClick={() => {this.setState({currentPhase:5})}}>keltainen</ColorLink> ja <ColorLink component="button" style={{color:'#4BB556'}} onClick={() => {this.setState({currentPhase:5})}}>vihreä</ColorLink> tunnelmakeskeisiä. Tuntuuko tutulta?</p>
              <p>Lue lisää temperamenttiälystä eli värityyppien ymmärtämisestä Minna Oulasmaan ja Mika Pesosen kirjasta Tunnista tyyppisi (Otava&nbsp;2020).</p>
              </ResultPaper>}
            
            {this.state.currentPhase == 3 && <SharePaper>
              <FacebookShareButton url={'http://tunnistatyyppisi.fi'}><FacebookIcon size={64} round={false} /></FacebookShareButton>
              <TwitterShareButton url={'http://tunnistatyyppisi.fi'} title="Tunnista tyyppisi -temperamenttitesti kertoo, mitä värejä sinusta löytyy." via="" hashtags={['tunnistatyyppisi']}><TwitterIcon size={64} round={false} /></TwitterShareButton>
              <LinkedinShareButton url={'http://tunnistatyyppisi.fi'}><LinkedinIcon size={64} round={false} /></LinkedinShareButton>
              <WhatsappShareButton url={'http://tunnistatyyppisi.fi'} title={'Tunnista tyyppisi -temperamenttitesti kertoo, mitä värejä sinusta löytyy.'}><WhatsappIcon size={64} round={false} /></WhatsappShareButton>
            </SharePaper>}
            
            {this.state.currentPhase == 3 && <BuyButton key="buy" variant="contained" color="primary" fullWidth={true} onClick={() => this.buyBook()}>Osta kirja</BuyButton>}
            
            {this.state.currentPhase == 3 && <TonyPaper><p>Testi on laadittu yhteistyössä Tony Dunderfeltin ja Dialogia Oy:n kanssa.</p></TonyPaper>}
            
            {this.state.currentPhase == 5 && <ResultPaper style={{marginTop:'3%'}}>
              <ColorLink component="button" onClick={() => {this.setState({currentPhase:3})}}>[X] Sulje</ColorLink>
              <p><span style={{color:'#FF4C30'}}>Punainen</span> eli asiakeskeinen ekstrovertti on nopea käänteissään. Hän on suora, kunnianhimoinen, vaativa ja kilpailunhaluinen, sillä tuloksethan ratkaisevat! Punainen nauttii uusista haasteista. Hän ei halua tarttua yksityiskohtiin vaan maalaa lavealla pensselillä suuria linjoja. Punaisen ehdottomuus voi tehdä hänestä tahtomattaan yksinäisen suden, mutta ystävänä hän on vankkumattoman rohkea, vahva ja suoraselkäinen.</p>
              <p><span style={{color:'#FFEC50'}}>Keltainen</span> eli tunnelmakeskeinen ekstrovertti on valloittava ja iloinen ihminen, joka ei jää huomaamatta. Hän on seurallinen juhlien ja yllätysten järjestäjä. Keltaisen tunteiden ilmaisuvoima on joskus liiallista, ja huomion valokeilassa viihtyvänä hän voi viedä hapen muilta. Luova ja kahleeton keltainen turhautuu tehtävistä, jotka vaativat totista ja tiukkaa sääntöjen noudattamista. Hänen mielestään on tärkeintä, että kaikilla on hauskaa!</p>
              <p><span style={{color:'#4BB556'}}>Vihreä</span> eli tunnelmakeskeinen introvertti on vakaa ja luotettava kaikkien kaveri. Vihreä ei hakeudu tapahtumien keskipisteeksi vaan tekee hiljaista taustatyötä kunnianhimoisempien apurina. Vihreä on joustava ja ajattelee muiden parasta – joskus liiallisuuksiin asti. Keskittyessään miellyttämiseen hänen on vaikea tunnistaa omia tarpeitaan ja mielipiteitään. Vihreän kannattaa harjoitella jämäkkyyttä ja kasvaa hyvällä tavalla itsekkääksi.</p>
              <p><span style={{color:'#2789CB'}}>Sininen</span> eli asiakeskeinen introvertti on analyyttinen tarkkailija, joka haluaa, että hänen reviiriään kunnioitetaan. Hän ei pidä yllätyksistä ja suunnitelmien muutoksista. Jos muut eivät piittaa säännöistä ja ohjeista, sininen opastaa heitä. Hän arvostaa tarkkuutta, mutta toiset pitävät häntä ehkä pilkunviilaajana. Sininen viihtyy hyvin omassa seurassaan, ja hänen harvalukuiset ystävyyssuhteensa ovat usein pitkäkestoisia ja antoisan syvällisiä. </p>
            </ResultPaper>}
            
            {this.state.currentPhase == 4 && <ResultPaper>
              <ExpansionPanel square={false} expanded={this.state.panelExpanded === 'panel1'} onClick={(e) => this.handleExpandClick(e,'panel1')}>
                <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Sait vihreitä {this.state.answers.filter(a => {return a.choice == "vihreä";}).length}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    Tarinaa vihreistä
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel square={false} expanded={this.state.panelExpanded === 'panel2'} onClick={(e) => this.handleExpandClick(e,'panel2')}>
                <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>Sait vihreitä {this.state.answers.filter(a => {return a.choice == "keltainen";}).length}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    Tarinaa keltaisista
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel square={false} expanded={this.state.panelExpanded === 'panel3'} onClick={(e) => this.handleExpandClick(e,'panel3')}>
                <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>Sait vihreitä {this.state.answers.filter(a => {return a.choice == "punainen";}).length}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    Tarinaa punaisista
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel square={false} expanded={this.state.panelExpanded === 'panel4'} onClick={(e) => this.handleExpandClick(e,'panel4')}>
                <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography>Sait vihreitä {this.state.answers.filter(a => {return a.choice == "sininen";}).length}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    Tarinaa sinisistä
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </ResultPaper>}
            
          </Container>
        {/*<React.Fragment>
        <CssBaseline />
        <Container>
          <Grid container className={useStyles.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Paper className={useStyles.paper}>
                  {this.state.currentQuestion && <h1>{this.state.currentQuestion.question}</h1>}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                {this.state.currentQuestion ? this.state.currentQuestion.choices.map(c => 
                  <Grid key={c.value} item>
                    <Paper className={useStyles.paper}><Button key={`${this.state.currentQuestion.id}_${c.value}`} variant="contained" color="primary" fullWidth={true} className={useStyles.button}  onClick={(e) => this.handleChoiceClick(e, c.value, this.state.currentQuestion.id)}>{c.title}</Button></Paper>
                  </Grid>) : ''}  
              </Grid>  
            </Grid>
          </Grid>
            <Grid item xs={12}>
            
              <Paper className={useStyles.paper}>
                test
              </Paper>
            </Grid>
            
            <Grid item xs={12}>
            <Paper className={useStyles.paper}>
              <Grid item xs={12}>{this.state.currentQuestion && <h1>{this.state.currentQuestion.question}</h1>}</Grid>
          
          </Paper>
          <Paper className={useStyles.paper}>
          <span>{this.state.currentQuestion ? this.state.currentQuestion.id : 0} / {quiz.questions.length}</span>
          {this.state.answers && 
            <div>
              <div><span>Vihreitä</span>:<span>{this.state.answers.filter(a => {return a.choice == "vihreä";}).length}</span></div>
              <div><span>Punaisia</span>:<span>{this.state.answers.filter(a => {return a.choice == "punainen";}).length}</span></div>
              <div><span>Keltaisia</span>:<span>{this.state.answers.filter(a => {return a.choice == "keltainen";}).length}</span></div>
              <div><span>Sinisiä</span>:<span>{this.state.answers.filter(a => {return a.choice == "sininen";}).length}</span></div>
            </div>
          }
          </Paper>
            </Grid>
          
        
        </Container>
        </React.Fragment>*/}
        </React.Fragment>
      );
    }
}

export default App;
